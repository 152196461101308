import { MapkitService } from 'core/api';
import { createContext, useEffect, useState } from 'react';

export const MapkitContext = createContext<{mapkit: typeof mapkit} | undefined>(undefined);

export const MapkitProvider = ({children}: {children: React.ReactNode}) => {

  const [context, setContext] = useState<{mapkit: typeof mapkit} | undefined>();

  useEffect(() => {
    const scriptLoader = async () => {
      if (window.mapkit !== undefined) { // ensure mapkit is loaded 
        const response = await MapkitService.getMapkitToken();
        mapkit.init({
          authorizationCallback: (done) => done(response.token),
        });
    
        setContext({
          mapkit
        });
      }
    }

    scriptLoader();
  }, []);

  return (
    <MapkitContext.Provider value={context}>
      {children}
    </MapkitContext.Provider>
  );
}
