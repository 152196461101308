import { Comparison, FormField, HouseholdPerson, Registration } from 'core/api';

export interface Values {
  [key: number]: string | string[];
}

export const getAgeFromBirthdate = (dateString: string) => {
  if (!dateString || dateString === '') {
    return;
  }

  const today = new Date();
  const birthDate = new Date(dateString);
  const month = today.getMonth() - birthDate.getMonth();

  let age = today.getFullYear() - birthDate.getFullYear();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const getUSDFormat = (amount: string | number) => {
  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return dollarUS.format(+amount);
};

export const buildComparisonExpression = (left: string | string[], operator: string, right: string): boolean => {
  if (Array.isArray(left)) {
    switch (operator) {
      case Comparison.EQ:
        return left.length === 1 && left[0] === right;
      case Comparison.NEQ:
        return left.length !== 1 || left[0] !== right;
      case Comparison.CONTAINS:
        return left.includes(right);
      case Comparison.NOT_CONTAINS:
        return !left.includes(right);
      default:
        return false;
    }
  } else {
    switch (operator) {
      case Comparison.EQ:
        return left === right;
      case Comparison.NEQ:
        return left !== right;
      case Comparison.GT:
        return left > right;
      case Comparison.GTE:
        return left >= right;
      case Comparison.LT:
        return left < right;
      case Comparison.LTE:
        return left <= right;
      case Comparison.CONTAINS:
        return left.includes(right);
      case Comparison.NOT_CONTAINS:
        return !left.includes(right);
      default:
        return false;
    }
  }
};

export const isVisible = (rules: FormField['visibilityRules'] | undefined, values: Values) => {
  if (!rules || rules.length === 0) {
    return true;
  }

  const checks = rules.map((rule) => {
    return buildComparisonExpression(values[rule.targetFormFieldId], rule.comparison, rule.answerToMatch);
  });

  return rules[0].chainingComparison === 'and' ? !checks.some((c) => !c) : checks.some((c) => c);
};

export function toShortDollar(pennies: number) {
  return (pennies / 100).toFixed(pennies % 100 === 0 ? 0 : 2);
}

export function totalLineItems(registration: Registration) {
  return getUSDFormat(registration.lineItems.reduce((total, item) => total + item.amount, 0) / 100);
}

export function getRegFeeLineItem(registration: Registration) {
  return registration.lineItems.find((l) => l.isRegistrationFee);
}

export const sortPersonByAgeThenFirstName = (a: HouseholdPerson, b: HouseholdPerson) => {
  const ageA = a.person.age === 0 ? 0 : a.person.age || 99;
  const ageB = b.person.age === 0 ? 0 : b.person.age || 99;
  if (ageA === ageB) {
    return a.person.firstName.localeCompare(b.person.firstName);
  }
  return ageA - ageB;
};

// This exists so we can make apple mapkit abbreviated state codes to state values
// in our form fields.
export const US_STATES = [
  {
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    name: 'California',
    abbreviation: 'CA'
  },
  {
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC'
  },
  {
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY'
  }
];

export const CA_PROVINCES = [
  {
    name: 'Alberta',
    abbreviation: 'AB'
  },
  {
    name: 'British Columbia',
    abbreviation: 'BC'
  },
  {
    name: 'Manitoba',
    abbreviation: 'MB'
  },
  {
    name: 'New Brunswick',
    abbreviation: 'NB'
  },
  {
    name: 'Newfoundland and Labrador',
    abbreviation: 'NL'
  },
  {
    name: 'Northwest Territories',
    abbreviation: 'NT'
  },
  {
    name: 'Nova Scotia',
    abbreviation: 'NS'
  },
  {
    name: 'Nunavut',
    abbreviation: 'NU'
  },
  {
    name: 'Ontario',
    abbreviation: 'ON'
  },
  {
    name: 'Prince Edward Island',
    abbreviation: 'PE'
  },
  {
    name: 'Quebec',
    abbreviation: 'QC'
  },
  {
    name: 'Saskatchewan',
    abbreviation: 'SK'
  },
  {
    name: 'Yukon Territory',
    abbreviation: 'YT'
  }
]