/* eslint-disable no-cond-assign */
import { debounce } from 'lodash';
import { ChangeEvent, InputHTMLAttributes, useContext, useCallback, useState, useEffect } from 'react';
import { MapkitContext } from 'state/context/MapkitProvider';
import { Icon } from '@teamsnap/snap-ui';
import { useFormSelector } from 'state/form/formSlice';

type Search = mapkit.Search;
type Region = mapkit.CoordinateRegion;

export interface PlaceType {
  coordinate: {
    latitude: number;
    longitude: number;
  };
  displayLines: string[];
}

const filterResults = (result: PlaceType): boolean =>
  result?.displayLines[1] !== 'Search Nearby' &&
  result?.displayLines[1] !== 'Show on Map';

export interface AutoCompleteAddress {
  street?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
}

function parseAddress(address: string, postalCode?: string) {
  /**
   * Addresses from apple maps can be in a number of different formats, and this attempts to 
   * parse them to meet the majority of use cases. The following are examples of the formats that
   * this function can handle:
   * 
   * '88 Queens Quay W, Toronto ON M5J 0B6, Canada'
   * '131 Loraview Ln, Aurora, ON, Canada'
   * '249 Queens Quay W, Toronto, ON, Canada'
   * '8455 NW 53rd St, Miami, FL  33166, United States'
   * 'Doral, FL, United States'
   * '6951 Locke Point Dr NE, Minneapolis, MN, United States'
   */
  const parts = address.split(',').map(part => part.trim());

  const result: AutoCompleteAddress = {
    street: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined,
  };

  if (parts.length === 2) {
    result.state = parts[0];
  } else if (parts.length === 3) {
    if (postalCode) {
      result.street = parts[0];

      const [city, state] = parts[1].split(' ');
      result.city = city;
      result.state = state;
    } else {
      result.city = parts[0];
      result.state = parts[1];
    }
    
  } else if (parts.length === 4) {
    result.street = parts[0];
    result.city = parts[1];

    const [state] = parts[2].split(' ');
    result.state = state
  }

  result.postalCode = postalCode;
  return result;
}

export const LocationAutoCompleteInput = ({onSelectLocation, ...props}: InputHTMLAttributes<HTMLInputElement> & {onSelectLocation: (address: AutoCompleteAddress) => void}) => {
  const mapkitContext = useContext(MapkitContext);
  const [search, setSearch] = useState<Search | undefined>();
  const [results, setResults] = useState<mapkit.SearchAutocompleteResult[] | undefined>();
  const [region, setRegion] = useState<Region | undefined>();
  const currentForm = useFormSelector();
  
  const configureRegion = () => {
    if (mapkitContext?.mapkit && currentForm?.organizationZipcode) {
      let place;
      
      const geocoder = new mapkitContext.mapkit.Geocoder({
        language: 'en',
        getsUserLocation: false,
      });
      
      geocoder.lookup(currentForm.organizationZipcode, (error, data) => {
        if (!error) {
          // handle error
          place = data.results.find((place) => place.postCode === currentForm.organizationZipcode && place.coordinate)
          if (!place) return false;

          setRegion(
            new mapkitContext.mapkit.CoordinateRegion(
              new mapkitContext.mapkit.Coordinate(
                place.coordinate.latitude,
                place.coordinate.longitude
              ),
              new mapkitContext.mapkit.CoordinateSpan(1, 1)
            )
          );
          return true;
        }
      },{
        limitToCountries: 'US,CA',
      });
    }
  };
  
  useEffect(() => {
    if (!region && currentForm?.organizationZipcode && mapkitContext?.mapkit) {
      configureRegion();
    }
  }, [mapkitContext, currentForm]);


  useEffect(() => {
    if (mapkitContext?.mapkit) {
      const searchOptions: mapkit.SearchConstructorOptions = {
        includePointsOfInterest: false,
        limitToCountries: 'US,CA',
        includeAddresses: true,
      };

      if (!currentForm?.organizationZipcode) {
        searchOptions.getsUserLocation = true;
      }
      
      if (region) {
        searchOptions.region = region;
      }

      setSearch(new mapkitContext.mapkit.Search(searchOptions));
    }
  }, [mapkitContext, region, currentForm]);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event);
    }

    if (!event.target.value) return;
    
    if (search) {
      search.autocomplete(event.target.value, (error, data) => {
        setResults(data?.results.filter(filterResults));
      });
    }
  };

  const debouncedhandleInput = useCallback(debounce(handleInput, 500), [
    search,
  ]);

  const handleSelection = (place: mapkit.SearchAutocompleteResult) => {
    setResults([]);
    if (mapkitContext?.mapkit) {
      // create a search with the auto complete results to find the details of the address
      const placeSearch = new mapkitContext.mapkit.Search();
      placeSearch.search(place, (error, data) => {
        if (!error && data.places && data.places.length > 0) {
          const place = data.places[0];
          const address = {
            street: place.fullThoroughfare,
            city: place.locality,
            state: place.administrativeAreaCode,
            postalCode: place.postCode,
          }

          onSelectLocation(address);
        }
      });
    }
  };

  return (
    <div className="sui-relative w-full">
      <input {...props} 
        onBlur={(event) => {
          if (!event.target?.parentNode?.contains(event.relatedTarget)) {
            setResults(undefined);
          }
        }} 
        onChange={(e) => {
          debouncedhandleInput(e);
          props.onChange && props.onChange(e);
        }}
      />
      {results && results.length > 0 && (
        <div className='location-autocomplete-dropdown sui-shadow-1' data-testid="location-autocomplete-dropdown">
          {results.map((place, i) => (
            <button
              type="button"
              key={i}
              onClick={() => handleSelection(place)}
            >
              <Icon name="location_on" className="Icon"/>
              <span className="u-textSemiBold">{place.displayLines[0]}</span>
              <span>{place.displayLines[1]}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
