/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MapkitToken } from '../models/MapkitToken';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MapkitService {

    /**
     * Returns a temporary token to use with mapkit APIs
     * @returns MapkitToken Success
     * @throws ApiError
     */
    public static getMapkitToken(): CancelablePromise<MapkitToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/mapkit',
        });
    }

}
